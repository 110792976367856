:root {
	// --heights: 50vh;
	// --widths: 100%;
	--heights: 403px;
	--widths: 403px;
}

.active {
	display: inline-block;
}

.inactive {
	display: none;
}

.slides {
	height: var(--heights);
	width: var(--widths);
	position: relative;
}

.slide-image {
	width: 100%;
	height: auto;
	// margin-top: -5rem;
	object-fit: cover;
}

.crausal_container {
	display: flex;
	flex-direction: column;
	justify-content: center;
}

.slide-title,
.slide-text {
	width: 100%;
	height: 100%;
	color: white;
	font-size: 50px;
	position: absolute;
	text-align: center;
	top: 40%;
	z-index: 10;
}

.slider-div{
	margin-bottom: 35px;
}

.slide-text {
	top: 65%;
	font-size: 2rem;
}

.slider-descriptions-info{
	margin-left: 15px;
	margin-right: 15px;
	font-size: 10px;
	font-style: italic;
}

.prev,
.next {
	cursor: pointer;
	z-index: 100;
	position: absolute;
	top: 50%;
	width: auto;
	padding: 1rem;
	margin-top: -3rem;
	font-size: 40px;
	font-weight: bold;
	border-radius: 0px 5px 5px 0px;
}

.prev:hover,
.next:hover {
	color: white;
	background-color: rgba(0, 0, 0, 0.6);
	transition: all 0.5s ease-in;
}

.next {
	right: 0;
	border-radius: 5px 0px 0px 5px;
}

.all-dots {
	// width: 100%;
	// height: 100%;
	// position: absolute;
	// display: flex;
	// top: 95%;
	// justify-content: center;
	margin-top: 5rem;
	z-index: 200;
}
.dot {
	cursor: pointer;
	margin: 0px 3px;
	width: 8px;
	height: 8px;
	left: 46px;
	top: 0px;
	background: rgba(11, 83, 148, 0.1);
	border-radius: 200px;
	border-radius: 50%;
	display: inline-block;
}
.dot_content {
	margin-top: 10%;
}
.active-dot {
	width: 38px;
	height: 8px;
	left: 0px;
	top: 0px;
	/* Primary */
	background: #0b5394;
	border-radius: 6px;
}

.dashboard_modification {
	font-family: Manrope;
	font-style: normal;
	font-weight: bold;
	font-size: 20px;
	line-height: 28px;
	/* identical to box height, or 140% */
	text-align: center;
	letter-spacing: 0.01em;
	/* gray/900 */
	color: #111827;
}

.title_description {
	font-family: Manrope;
	font-style: normal;
	font-weight: normal;
	font-size: 14px;
	line-height: 20px;
	/* or 143% */
	text-align: center;
	letter-spacing: 0.01em;
	/* gray/600 */
	color: #4b5563;
}

// MacBook Pro 2016 Resolution height:1600
@media all and (max-width: 2560px) {
	.all-dots {
		margin-top: 20rem;
		z-index: 200;
		text-align: center;
	}
	// comment
}

// MacBook Pro 2016 Viewport height:800
@media all and (max-width: 1280px) {
	.all-dots {
		margin-top: 8rem;
		z-index: 200;
	}
}

// Full HD height:1080
@media all and (max-width: 1920px) {
	.all-dots {
		margin-top: 10rem;
		z-index: 200;
	}
}
// Desktop 1366X768
@media all and (max-width: 1366px) {
	.all-dots {
		margin-top: 2rem;
		z-index: 200;
	}
}

@primary-color: #0B5394;