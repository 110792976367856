.create-deal-form {
  margin-left: 80px;
  margin-right: 80px;

  .ant-form-item-required::after {
    display: block !important;
    margin-right: 4px;
    color: #ff4d4f;
    font-size: 14px;
    font-family: SimSun, sans-serif;
    line-height: 1;
    content: "*";
  }

  .spv-documents {
    .ant-upload {
      width: 100% !important;
    }
  }

  .ant-list {
    align-items: center;
  }

  .ant-tabs-content {
    padding-top: 0;
  }

  .space-between-row {
    display: flex !important;
    justify-content: space-between !important;
    margin-top: 40px;
    padding-top: 40px;

    .ant-form-item-control-input-content {
      border-top: 1px solid #c4c4c4;
      padding-top: 40px;
      display: flex !important;
      justify-content: space-between !important;
    }
  }

  .dragger {
    height: fit-content;

    .ant-upload-list {
      display: block;
    }
  }

  // .line {
  //   margin-top: 24px;
  //   margin-bottom: 24px;

  //   .ant-form-item-control-input-content {
  //     display: block !important;
  //   }

  //   hr {
  //     border-top: 1px solid rgba(229, 231, 235, 1);
  //   }
  // }

  .ant-form-item-label {
    margin-bottom: 6px;
    font-family: "Manrope";
    font-size: 14px;
    font-weight: 600;
    line-height: 20px;
    letter-spacing: 0em;
    text-align: left;
    color: rgba(55, 65, 81, 1) !important;
  }

  .ant-btn-primary {
    border-color: #0b5394;
    background: #0b5394;
    font-family: "Manrope";
    font-size: 14px;
    font-weight: 300;
    line-height: 20px;
    letter-spacing: 0.01em;
    text-align: center;
  }

  .form-link {
    .ant-input-group-addon {
      height: 32px;
    }

    .ant-input {
      border-top-left-radius: 0 !important;
      border-bottom-left-radius: 0 !important;
    }
  }

  .ant-btn {
    border-radius: 6px;
  }

  .currency-input {
    .ant-input {
      border-top-right-radius: 0 !important;
      border-bottom-right-radius: 0 !important;
    }
    .ant-input-number {
      border-radius: 6px;
    }
    .ant-input-number-group-addon {
      border-radius: 6px;
      border-top-left-radius: 0 !important;
      border-bottom-left-radius: 0 !important;
    }
    .ant-input-group-addon {
      height: 32px;
    }
  }

  .ant-form-item-control-input-content {
    display: flex;
    justify-content: space-between;

    .ant-form-item {
      width: 49%;
    }
  }

  .document-dragger {
    height: fit-content;

    .ant-upload-list {
      display: block;
    }
  }
}

.hide {
  display: none;
}

.visible {
  display: block;
}

.modal-form {
  max-width: "430px";

  &__body {
    margin-top: 20px;
  }

  .lable {
    margin-bottom: 6px;
    font-family: "Manrope";
    font-size: 14px;
    font-weight: 600;
    line-height: 20px;
    letter-spacing: 0em;
    text-align: left;
    color: rgba(55, 65, 81, 1) !important;
  }

  .ant-modal-body {
    margin-bottom: 0;
  }

  .ant-modal-footer {
    .ant-btn-primary {
      border-color: #0b5394;
      background: #0b5394;
      font-family: "Manrope";
      font-size: 14px;
      font-weight: 300;
      line-height: 20px;
      letter-spacing: 0.01em;
      text-align: left;
    }

    .ant-btn {
      border-radius: 6px;
    }

  }
  .past-fundraise {
    border-radius: 6px ;
    .ant-input {
      border-top-right-radius: 0 !important;
      border-bottom-right-radius: 0 !important;
    }
    .ant-input-number-group-addon {
      border-radius: 6px;
      border-top-left-radius: 0 !important;
      border-bottom-left-radius: 0 !important;
    }
    .ant-input-group-addon {
      height: 32px;
    }
  }

  .ant-modal-header {
    display: flex;
    justify-content: center;
    background-color: #f9fafb;
    border-bottom: rgba(249, 250, 251, 1);
    border-radius: inherit;
  }

  .ant-select {
    width: 100%;
  }
}

.avatar-uploader {
  margin-left: 30px;

  &__button {
    border-radius: 6px !important;
  }
}
.grant-image {
  margin-left: 0;
  &__button {
    border-radius: 6px;
  }
}

.ifame-modal-form {
  width: 90% !important;
  height: 90% !important;

  .doc-iframe {
    width: 100%;
    height: 100%;
  }

  .ant-modal-header {
    border-bottom: none !important;
    border-radius: inherit;
  }

  .ant-modal-content {
    height: 95% !important;

    .ant-modal-body {
      height: 95% !important;
    }
  }
}
.actions{
  display: flex;
  gap: 10px;
}
.ant-picker {
  border-radius: 6px !important;
}

@media (max-width: 768px) {
  .create-deal-form {
    margin: 20px;
  }
}

@media (max-width: 768px) {
  .pb-24-ex {
    padding-bottom: 24px;
  }
}

.pb-15 {
  padding-bottom: 15px !important;
  margin-bottom: 0 !important;
}

.icon-black {
  color: #262626;
}

.edit-icon {
  position: relative;
  right: 40px;
  top: 22px;
  z-index: 9;
}

.edit-icon-back {
  position: absolute;
  top: 0;
  right: 0px;
  height: 25px;
}

.financial-statement-datapicker {
  width: 100%;
}


.files-list{
  list-style-type: none;
  padding: 0;
  max-width: 55%;
  margin-right: auto;
}

.files-list li {
  padding: 0.2rem 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.files-list li .download {
  border: 0;
  background: none;
  color: blue;
  cursor: pointer;
}


.list-requests { 
  flex: 1;
  background: white;
  margin: 3rem 0 0 0;
}

.action-button {
  border: 0;
  background: none;

}

.approve { 
  color: green;
}
.reject { 
  color: red;
}