.deal-table-card {
  .card-grid {
    width: 100%;

    &__item {
      display: flex;
      justify-content: space-between;
      align-items: center;

      .card-progress {
        width: 30%;
      }
    }
  }
}
.outer-dot {
  height: 10px;
  width: 10px;
  border-radius: 50%;
  background-color: yellow;
  display: flex;
  justify-content: center;
  align-items: center;
}
.inner-dot {
  height: 6px;
  width: 6px;
  border-radius: 50%;
  background-color: #ef4444;
}
.center {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.edit-menu {
  min-width: 170px;

  .ant-dropdown-menu-title-content {
    display: flex;
    color: #8c8c8c;

    p {
      margin-bottom: 0;
    }
  }

  &__icon {
    margin-right: 5px;
  }
}

.menu-icon-size {
  height: 20px;
  width: 15px;
}

.divider-margin {
  margin: 0px !important;
}

.approve-modal {
  text-align: center !important;

  .ant-modal-footer {
    text-align: center;
    display: flex;
    justify-content: center;
    padding: 10px 24px;
  }

  .approve-modal-title {
    font-family: "Manrope";
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 28px;
    letter-spacing: 0.01em;
    color: #101828;
  }

  .approve-modal-desc {
    font-family: "Manrope";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    text-align: center;
    letter-spacing: 0.01em;
    color: #6b7280;
  }

  .approve-modal-icon {
    background: #d1fae5;
    border: 8px solid #ecfdf3;
    border-radius: 28px;
  }

  .modal-yes-btn {
    //padding: 10px 18px;
    width: 100%;
    gap: 8px;
    background: #34d399;
    border: 1px solid #34d399;
    box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
    border-radius: 8px;
    font-family: "Manrope";
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.01em;
    color: #ffffff;
  }

  .modal-cancel-btn {
    //padding: 10px 18px;
    width: 100%;
    gap: 8px;
    background: #ffffff;
    border: 1px solid #d0d5dd;
    border-radius: 8px;
    font-family: "Manrope";
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.01em;
    color: #344054;
  }

  .reject-modal-yes-btn {
    //padding: 10px 18px;
    width: 100%;
    gap: 8px;
    background: #ef4444;
    border: 1px solid #ef4444;
    box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
    border-radius: 8px;
    font-family: "Manrope";
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.01em;
    color: #ffffff;
  }

  .reject-modal-icon {
    background: #fee4e2;
    border: 8px solid #fef3f2;
    border-radius: 28px;
  }
}

.ant-table {
  th {
    font-family: "Manrope";
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.01em;
    color: #6b7280;
  }
}

.avatar-info {
  margin-left: 12px;
}
.delete__modal {
  .ant-modal-confirm-title {
    margin-left: 8%;
  }
}

.tag_text {
  border-radius: 8px;
}
