@import url("https://fonts.googleapis.com/css2?family=Manrope:wght@500;700&display=swap");
// @import "../assets/styles/colors";
// @import "../assets/styles/basics";

.leftbar-background {
  background: rgba(11, 83, 148, 0.03);
  height: 100vh;
}

.align-slider-center {
  height: 100vh;
  row-gap: 0px;
  position: absolute;
  text-align: center;
  width: 100%;
  display: flex;
  align-items: center;
  top: 0;
}

.confirm-submit-btn {
  height: 49px;
  left: 0px;
  width: 100%;
  /* Primary */
  background: #0b5394;
  /* shadow/sm */
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.05);
  border-radius: 6px;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  /* identical to box height, or 143% */
  letter-spacing: 0.01em;
  /* white */
  color: #ffffff;
}

.confirm-submit-btn:hover {
  background: #0b5394 !important;
  color: #ffffff !important;
  border-color: #fff !important;
}

.confirm-submit-btn:focus {
  background: #0b5394 !important;
  color: #ffffff !important;
  border-color: #fff !important;
}

body {
  overflow-y: auto;
}

.login-text:hover {
  cursor: pointer;
}
.password-field {
  width: 100% !important;
  display: flex;
  height: 38px;
  flex-direction: row;
  justify-content: space-around;
}

.forgot-password {
  font-family: Manrope;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  text-align: right;
  letter-spacing: 0.01em;
  color: #0b5394;
}
.forgot-password:hover {
  cursor: pointer;
}
.option-text-splited {
  margin-top: -10px !important;
}

//styles.less css goes her
.text-center {
  text-align: center;
  margin: 0 auto;
  width: 403px;
  height: 403px;
  overflow-wrap: break-word;
}

.crousel-desc {
  text-align: center;
  margin: 0 auto;
  height: 200px !important;
}
// .crousel-details {
// 	background-color: #db4a39;
// 	display: flex;
// 	flex-direction: column;
// }
.crousel-desc {
  display: flex;
  flex-direction: column;
}
.logo-image {
  padding-left: 33px;
  padding-top: 33px;
  img {
    height: 65px;
  }
}

.crousel-desc {
  margin-top: 10% !important;
}

.registration-form-container {
  align-items: center;
  display: flex;
  justify-content: center;
  height: 100vh;
}

.image-slider {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}
.image-slider-dashboard {
  flex-direction: column;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}
.kyc {
  position: static;
  width: 25px;
  height: 25px;
  left: 0px;
  top: 0px;
  margin: 5px;
  flex-direction: column;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}
.pay-method {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50vh;
}

.container-left {
  background: rgba(11, 83, 148, 0.03);
}
.auth-page-ext {
  @media screen and (min-width: 768px) {
    margin: 50px 0 !important;
    max-height: 100% !important;
    overflow: auto !important;
    width: 100% !important;

    > div {
      height: 100%;
      .align-content {
        height: 100%;
      }
    }
  }
}
.auth-page {
  .auth-page-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    // height: 100vh;
  }

  .mobile-auth-warpper {
    padding: 30px 20px;
  }

  .auth-container h1 {
    font-weight: bold;
    margin: 0;
    color: #fff;
  }

  .ant-form-item-explain-error {
    font-size: 13px;
    text-align: left;
  }

  .auth-container h2.ant-typography {
    text-align: center;
  }

  .overlay-panel p {
    font-size: 14px;
    font-weight: 100;
    line-height: 20px;
    letter-spacing: 0.5px;
    margin: 20px 0 30px;
  }

  .auth-container {
    background: #fff;
    border-radius: 10px;
    box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
    position: relative;
    overflow: hidden;
    width: 992px;
    max-width: 100%;
    // min-height: 660px;
  }

  .form-container form {
    background: #fff;
    padding: 50px;
    // height: 100%;
  }

  .form-container form h1 {
    color: var(--text-color);
    text-align: center;
  }
  .social-container {
    // justify-content: center;
    // color: #000 !important;
    // width: 100%;
    // height: 42px;
    // background: #ffffff;
    // border: 1px solid #d1d5db !important;
    // border-radius: 6px !important;
    // padding: 0px !important;
    text-align: center !important;
    align-items: center !important;
    border-radius: 6px !important;
    justify-content: center !important;
    margin: auto !important;
    align-items: center !important;
    width: 100% !important;
    border: 1px solid #d1d5db !important;
    font-style: normal !important;
    font-weight: 500 !important;
    font-size: 16px !important;
    line-height: 24px !important;
    color: #6b7280 !important;
    box-shadow: none !important;
  }

  .google-btn {
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 100% !important;
    text-align: center !important;
    align-items: center !important;
    // border-radius: 6px !important;
    justify-content: center !important;
    box-shadow: none !important;
    height: 42px;
    /* white */
    background: #ffffff;
    /* gray/300 */
    border: 1px solid #d1d5db;
    box-sizing: border-box;
    border-radius: 6px;

    font-family: "Manrope";
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    /* identical to box height, or 150% */

    letter-spacing: 0.01em;

    color: #374151;
  }
  .google-btn:hover {
    cursor: pointer;
  }
  .flex-0 {
    width: 18px;
    height: 18px;
    margin-bottom: 0.5rem;
    margin-right: 10px;
  }
  .flex-1 {
    margin-left: 10px;
    font-family: "Manrope";
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.01em;
    color: #6b7280;
  }
  .google-icon {
    height: 20px;
    width: 20px;
  }

  .card-registration {
    border-radius: 8px;
    margin-bottom: 10px !important;
    text-align: left;
    overflow-wrap: break-word;
  }
  .social-container .facebook {
    background: #4267b2;
    border-color: #4267b2;
  }

  .social-container .google {
    background: #db4a39;
    border-color: #db4a39;
  }
  .social-container .linkedin {
    background: #0e76a8;
    border-color: #0e76a8;
  }

  .form-container input {
    font-size: 14px;
  }

  .bg-gradient {
    background: var(--main-color);
    //background: -webkit-linear-gradient(to right, #38ef7d, var(--main-color));
    background: linear-gradient(to right, #38ef7d, var(--main-color));
  }

  // button {
  //     color: #fff;
  //     font-size: 12px;
  //     letter-spacing: 1px;
  //     transition: transform 20ms ease-in;
  //     cursor: pointer;
  //     margin: 8px 0;
  //     width: 100%;
  // }

  button:active {
    transform: scale(0.95);
  }

  button:focus {
    outline: none;
  }

  .divider-text {
    width: 100%;
    text-align: center;
  }

  .form-submit-btn {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 9px 17px;
    position: static;
    width: 100%;
    height: 38px;
    left: 0px;
    /* Primary */
    background: #0b5394;
    /* shadow/sm */
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.05);
    border-radius: 6px;
    margin: 24px 0px;

    font-family: Manrope;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    /* identical to box height, or 143% */
    letter-spacing: 0.01em;
    /* white */
    color: #ffffff;
    border: none;

    &::after {
      all: unset !important;
    }
  }

  button.ghost {
    background: transparent;
    border-color: #fff;
  }

  .form-container {
    position: relative;
    // top: 0;
    // height: 100%;
    //transition: all 0.6s ease-in-out;
  }

  .sign-in-container {
    left: 0;
    width: 50%;
    z-index: 2;
  }

  .sign-up-container {
    left: 0;
    width: 50%;
    z-index: 1;
    opacity: 0;
  }

  .overlay-container {
    position: absolute;
    top: 0;
    left: 50%;
    width: 50%;
    height: 100%;
    overflow: hidden;
    transition: transform 0.6s ease-in-out;
    z-index: 100;
  }

  .overlay {
    height: 100%;
    color: #fff;
    position: relative;
    left: -100%;
    width: 200%;
    transform: translateX(0);
    transition: transform 0.6s ease-in-out;
  }

  .overlay-panel {
    position: absolute;
    top: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding: 0 40px;
    height: 100%;
    width: 50%;
    transform: translateX(0);
    transition: transform 0.6s ease-in-out;
  }

  .overlay-panel button,
  .overlay-panel button {
    color: #fff !important;
    background-color: transparent !important;
    border-color: #fff !important;
  }

  .overlay-right {
    right: 0;
    transform: translateX(0);
  }

  .overlay-left {
    transform: translateX(-20%);
  }

  .ant-form-item-control-input-content {
    justify-content: space-between;
    display: flex;
    font-size: 14px;
  }

  /* Animations */
  /* move signin to the right */
  .auth-container.right-panel-active .sign-in-container {
    transform: translateX(100%);
  }

  /* Move overlay to the left */
  .auth-container.right-panel-active .overlay-container {
    transform: translateX(-100%);
  }

  /* Bring signup over signin */
  .auth-container.right-panel-active .sign-up-container {
    transform: translateX(100%);
    opacity: 1;
    z-index: 5;
  }

  /* move overlay back to the right */
  .auth-container.right-panel-active .overlay {
    transform: translateX(50%);
  }

  .auth-container.right-panel-active .overlay-left {
    transform: translateX(0);
  }

  .auth-container.right-panel-active .overlay-right {
    transform: translateX(20%);
  }
}

.highlight-card {
  border-radius: 8px;
  margin-bottom: 10px !important;
  text-align: left;
  overflow-wrap: break-word;
  background: rgba(11, 83, 148, 0.05) !important;
  /* Primary */
  border: 1px solid #0b5394 !important;
  box-sizing: border-box !important;
  border-radius: 10px !important;
}

.card-registration:hover {
  cursor: pointer;
}

.highlight-card:hover {
  cursor: pointer;
}

.registration-page {
  height: 100vh !important;
  overflow-y: hidden !important;
}
.registration-left-bar {
  background: rgba(11, 83, 148, 0.03);
  height: 100vh !important;
}

// .ant-carousel .slick-slide {
//     text-align: center;
//     height: 160px;
//     overflow: hidden;
// }

// .ant-carousel .slick-dots li button {
//     opacity: 1 !important;
//     background-color: white !important;
//     height: 8px !important;
//     border-radius: 10px !important;
//     margin-top: 5rem !important;
// }

.ant-carousel .slick-dots li.slick-active button {
  opacity: 1 !important;
  background: #0b5394 !important;
  width: 20px;
  height: 8px;
  margin-right: 10px !important;
  margin-left: 10px !important;
  border-radius: 6px;
  // margin-top: 1rem !important;
}
.dashboard-modifications {
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 28px;
  /* identical to box height, or 140% */
  text-align: center;
  letter-spacing: 0.01em;
  /* gray/900 */
  color: #111827;
}

.description {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  /* or 143% */
  text-align: center;
  letter-spacing: 0.01em;
  /* gray/600 */
  color: #4b5563;
}

// ################################Signup############################

.option-text {
  font-family: "Manrope";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  /* identical to box height, or 143% */
  letter-spacing: 0.01em;
  /* Text: Secondary */
  color: #6b7280;
  text-align: left;

  @media screen and (max-width: 768px) {
    text-align: center;
  }
}
.remember_me {
  font-family: Manrope;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  /* identical to box height, or 143% */
  letter-spacing: 0.01em;
  /* gray/900 */
  color: #111827;
}
.social-text {
  text-align: left;
  margin-top: 2rem;
  margin-bottom: 0.5rem !important;
  font-family: "Manrope";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  /* identical to box height, or 143% */
  letter-spacing: 0.01em;
  /* Button:Secondary */
  color: #374151;
}

.resend_link {
  font-family: "Manrope" !important;
  font-style: normal !important;
  font-weight: 600 !important;
  font-size: 14px !important;
  line-height: 20px !important;
  text-align: right !important;
  letter-spacing: 0.01em !important;
  color: #0b5394 !important;
  padding-left: 3px;
}

.verify_email {
  margin-top: 2rem;
}

.divider-text {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  /* identical to box height, or 143% */
  text-align: center;
  /* gray/500 */
  color: #6b7280;
}
.mt-2 {
  margin-top: -1rem !important;
}

.label-input {
  font-family: Manrope;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.01em;
  color: #374151;
  margin-bottom: 0px !important;
}
.input-field-password {
  font-family: "Manrope";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  /* identical to box height, or 143% */
  letter-spacing: 0.01em;
  /* Button:Secondary */
  color: red !important;
}

.i_agree {
  font-family: Manrope;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.01em;
  color: #111827;
}
.terms-service {
  font-family: Manrope;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  text-align: right;
  letter-spacing: 0.01em;
  color: #0b5394;
}
.existing-account {
  font-family: Manrope;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  letter-spacing: 0.01em;
  // margin-top: 1rem;
  color: #6b7280;
  text-align: center;
  width: 100%;
  display: block;
}
.login-text {
  font-family: Manrope;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  text-align: right;
  letter-spacing: 0.01em;
  color: #0b5394;
  padding-left: 0.3rem;
}
.login-text:hover {
  cursor: pointer;
}
.ant-form-item-label {
  padding: 0px !important;
}
::placeholder {
  font-family: "Manrope";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.01em;
  color: #6b7280;
}
.font_style {
  font-family: "Manrope";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.01em;
  color: #6b7280 !important;
}
.font_style .ant-input {
  font-family: "Manrope";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.01em;
  color: #6b7280;
}
.input-border {
  border-radius: 6px !important;
}
.lastname-left-padding {
  padding-left: 15px !important;
}
@media only screen and (max-width: 768px) {
  .lastname-left-padding {
    padding-left: 8px !important;
  }
}
.rm-margin-bottom {
  margin-bottom: 0px !important;
}
.align-content {
  // height: 100vh;
  row-gap: 0px;
  height: inherit;
  // position: absolute;
  text-align: center;
  width: 100%;
  display: flex;
  align-items: center;
  // top: 0;
}
.password-reset-link {
  text-align: center;
  font-family: "Manrope";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.01em;
  color: #6b7280;
}
// ####################### Verify Email###########################
.reset-link-instruction {
  font-family: Manrope;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  /* or 143% */
  letter-spacing: 0.01em;
  /* gray/600 */
  color: #4b5563;
  margin-top: -1rem;
  text-align: center;
}

.verify-email-text {
  font-family: Manrope;
  font-style: normal;
  font-weight: 800;
  font-size: 30px;
  line-height: 45px;
  letter-spacing: 0.01em;
  /* gray/900 */
  color: #111827;
}
.resend-link {
  font-family: Manrope;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  /* identical to box height, or 143% */
  text-align: right;
  letter-spacing: 0.01em;
  color: #6b7280;
}

.account-type-heading-title {
  font-family: Manrope;
  font-style: normal;
  font-weight: 800;
  font-size: 30px;
  line-height: 45px;
  /* identical to box height, or 150% */
  letter-spacing: 0.01em;
  /* gray/900 */
  color: #111827;
  text-align: left;
}

.account-type-heading-description {
  font-family: Manrope;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  /* or 143% */
  letter-spacing: 0.01em;
  /* gray/600 */
  color: #4b5563;
  text-align: left;
}

.error-message {
  color: red;
  font-family: "Manrope";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.01em;
  text-align: left;
}

.nofify-message {
  font-family: "Manrope";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.01em;
  text-align: left;
}

.success-msg {
  color: #52c41a;
}

.card-title-signup {
  font-family: Inter;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  /* identical to box height, or 143% */
  letter-spacing: 0.01em;
  /* gray/900 */
  color: #111827;
}
.card-desc-signup {
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  /* or 143% */
  letter-spacing: 0.01em;
  color: #6b7280;
}
.create-account {
  //margin-top: 30px;
  margin-bottom: 10px;
  font-family: Manrope;
  font-style: normal;
  font-weight: 800;
  font-size: 30px;
  line-height: 45px;
  letter-spacing: 0.01em;
  color: #111827;
  text-align: left;

  @media screen and (max-width: 768px) {
    text-align: center;
  }
}
.forgot-password-title {
  margin-left: 0px !important;
}

.ant-form-item-label
  > label.ant-form-item-required:not(
    .ant-form-item-required-mark-optional
  )::before {
  display: none !important;
  margin-right: 4px;
  color: #ff4d4f;
  font-size: 14px;
  font-family: SimSun, sans-serif;
  line-height: 1;
  content: "*";
}

.password-label {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100% !important;
  padding-bottom: 0.5rem;
}

.label-input-password {
  font-family: "Manrope";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  /* identical to box height, or 143% */
  letter-spacing: 0.01em;
  /* Button:Secondary */
}

.label-input-forgot-password {
  font-family: "Manrope";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  /* identical to box height, or 143% */
  text-align: right;
  letter-spacing: 0.01em;
  /* Primary */
  color: #0b5394;
}

.label-input-forgot-password:hover {
  cursor: pointer;
}

///*****************Slider*********************//

//**********  Media Query  **************
@media only screen and (min-width: 820px) and (max-width: 912px) {
  .crausal_container {
    margin-top: 15rem !important;
  }
  .auth-page {
    overflow-x: hidden !important;
    margin-top: 15rem !important;
  }
  .auth-page-ext {
    margin: 50px 0 !important;
  }
  .slider_descriptions {
    margin-top: -15rem !important;
  }
}
@media only screen and (min-width: 768px) and (max-width: 820px) {
  .crausal_container {
    margin-top: 10rem !important;
  }
  .auth-page {
    overflow-x: hidden !important;
    margin-top: 10rem !important;
  }
  .slider_descriptions {
    margin-top: -15rem !important;
  }
  .pd-lastname {
    padding-left: 0.5rem !important;
  }
}
@media only screen and (max-width: 768px) {
  .mobile_responsive {
    display: none !important;
  }
}
@media only screen and (min-width: 320px) and (max-width: 540px) {
  .mobile_responsive {
    display: none;
  }
  .auth-page {
    overflow-x: hidden !important;
    margin-left: 1rem;
  }
  .validation_error {
    color: red !important;
    margin-top: -1rem !important;
    margin-left: 0.5rem !important;
    text-align: left;
  }
}

.logo-image-mobile {
  display: none;

  img {
    height: 65px;
    margin: 20px auto;
  }
}

@media (max-width: 481px) {
  /* portrait e-readers (Nook/Kindle), smaller tablets @ 600 or @ 640 wide. */
  .mobile_responsive {
    display: none;
  }
  .auth-page {
    overflow-x: hidden !important;
    margin-left: 0rem;
  }
  .validation_error {
    color: red !important;
    margin-top: -1rem !important;
    margin-left: 0.5rem !important;
  }
}

@media (max-width: 641px) {
  /* portrait tablets, portrait iPad, landscape e-readers, landscape 800x480 or 854x480 phones */
  .mobile_responsive {
    display: none;
  }
  .validation_error {
    color: red !important;
    margin-top: -1rem !important;
    margin-left: 0.5rem !important;
  }
  // .align-content {
  // 	top: 16%;
  // }
}

// MacBook Pro 2016 Resolution height:1600
@media all and (max-width: 2560px) {
  .crausal-align {
    margin-top: 35% !important;
  }
}
// MacBook Pro 2016 Viewport height:800
@media all and (max-width: 1280px) {
  .crausal-align {
    margin-top: 15% !important;
  }
}

// Full HD height:1080
// @media all and (max-width: 1920px) {

// }

@media only screen and (min-width: 1820px) and (max-width: 1920px) {
  .crausal-align {
    margin-top: 20% !important;
  }
  .slider-component-container {
    margin-top: 2rem;
  }
  .dot-background {
    margin-top: -5rem !important;
  }
  .slider_descriptions {
    margin-top: -5rem !important;
  }
}
.pd-firstname {
  //padding-right: 0.5rem !important;
}

// ThinkPad height:601
@media all and (max-width: 1366px) {
  .crausal-align {
    margin-top: 0% !important;
  }
}
@media all and (max-width: 540px) {
  .dashboard_modification {
    margin-top: 30% !important;
  }
  .validation_error {
    color: red !important;
    margin-top: -1rem !important;
    margin-left: 0.5rem !important;
  }
}

.validation_error {
  color: red !important;
  margin-top: -1rem !important;
  text-align: left !important;
}
.password_warning {
  color: red;
  padding-left: 0px;
  // margin-top: -1rem !important;
  margin-left: -5rem !important;
}
.spinner_background {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 9999;
  width: 100%;
  height: 100%;
  padding-top: 100px;
  // background-color: white;
  // background-color: rgba(0, 0, 0, 0.4);
  -webkit-transition: 0.5s;
  overflow: auto;
  transition: all 0.3s linear;
  // filter: opacity(10);
  backdrop-filter: blur(8px);
}
.spinner {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.align-left {
  text-align: left;
}
.email-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.mobile-media {
  width: 100%;
}
@media only screen and (max-width: 600px) {
  // .align-content{
  // 	top: 192px !important;
  // }
}
@media only screen and (max-width: 768px) {
  .input-responsive {
    display: flex !important;
  }
  .mobile-media {
    width: 100%;
    margin-left: 0 !important;
    margin-right: 0 !important;
  }
  .ant-form-item-label {
    width: 100% !important;
  }
  .logo-image-mobile {
    display: flex !important;
    // justify-content: center;
    // margin-left: 0.1rem!important;
    // margin-top: 1rem;
  }
  .align-content {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100vw;
    // top: 153px;
  }
  .align-content-ext {
    width: auto;
  }
  .rm-offset {
    margin-left: 0 !important;
    max-width: 100%;
    // top: -160px
  }

  .rm-padding {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  .set-align {
    margin-right: 0 !important;
  }

  .ant-form-item {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }

  .fix-margin .ant-form-item-control-input {
    margin-right: -6px !important;
  }
}

.main-section {
  justify-content: center !important;

  @media screen and (min-width: 768px) {
    max-height: 100vh;
    display: flex;
    align-items: center;
  }
  @media screen and (max-width: 768px) {
    max-height: 100vh;
    overflow: auto;
  }
}

.slider-container {
  // 	display:flex;
  //   	flex-direction:column;
  //   	align-items:center;
  //   	width:100%;
  //   	height:30vh;
  //   	justify-content:space-evenly;
  justify-content: center;
  margin-bottom: 80px;
}

// .slider-div {
// 	border: solid 2px red;
//   	height: 33.33%;
//   	text-align: center;
// }

.slider-image {
  text-align: center;
  padding: 0 2rem;
}

.slider-descriptions {
  text-align: center;
  // padding: 1rem 1rem 4rem 1rem;
  padding-top: 25px;
}

.slider-dots {
  text-align: center;
}

.slider-descriptions-header {
  font-family: "Manrope";
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 28px;
  text-align: center;
  letter-spacing: 0.01em;
  color: #111827;
  margin-bottom: 0px;
}

.slider-descriptions-text {
  font-family: "Manrope";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  letter-spacing: 0.01em;
  color: #4b5563;
}

.slider-dots-position {
  position: absolute;
  bottom: 10%;
  right: 0;
  left: 0;
}

.strength-meter {
  margin-left: 10px;
  margin-right: 10px;

  .flex-container {
    display: flex;
    flex-direction: row;
    width: 100%;
    flex-wrap: nowrap;
    background-color: #ccc;
  }
  .h-4 {
    height: 4px;
  }
  .red {
    background-color: red;
    width: 33.33%;
  }

  .orange {
    background-color: orange;
    width: 33.33%;
  }

  .green {
    background-color: green;
    width: 33.33%;
  }

  p {
    text-align: right;
    color: #aaa;
  }
}

.mobile-media-ext {
  height: 100vh;
  overflow: hidden;
}
