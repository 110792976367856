.role-selector-container {
    display: flex;
    align-items: center;
    height: 100dvh;
    width: 100%;
}


.wrong-error{
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    padding: 1rem 0;
    align-items: center;
}

.card-select {
    width: 100%;
    max-width: none;
    align-self: stretch;

}
.card-select > div {
    height: 100%;
}

