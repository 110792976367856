@media screen and (max-width: 800px) {
  .marketplace_tab_header {
    display: flex;
    flex-direction: column;
  }
}

.marketplace_tab_header {
  display: flex;
  width: 100%;
  .ant-select-selection-item {
    font-size: 14px;
  }
  .ant-badge {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-left: 5px;
  }
  .ant-row {
    display: flex !important;
    align-items: center !important;
  }
}

.add-button {
  width: 140px;
  border-radius: 6px;
}
.center-filter-and-search {
  display: flex;
  justify-content: center;
  align-items: center;
}
.filter-btn {
  font-size: 25px;
  color: #6b7280;
}
.submit-button {
  .ant-form-item-control-input-content {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 20px;
    column-gap: 20px;
  }
}
