.header-content {
  display: flex;
  flex-direction: column;
}
.step-identity {
  font-family: Manrope;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.01em;
  text-transform: uppercase;
  color: #0b5394;
  margin-top: 50px;
}
.identity-verify-title {
  font-family: Manrope;
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 36px;
  letter-spacing: 0.01em;
  color: #111827;
  margin-top: -10px;
}

.select-doc-type {
  font-family: Inter;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.01em;
  color: #374151;
}

.onboarding-card {
  border-radius: 8px;
  margin-bottom: 10px !important;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 16px 25px 16px 16px;
  position: static;
  height: 72px;
  left: 0px;
  border: 1px solid #d1d5db;
}

.onboarding-card:hover {
  cursor: pointer;
}

.onboarding-card-clicked {
  border-radius: 8px;
  margin-bottom: 10px !important;
  border-color: #0b5394 !important;
  background: rgba(11, 83, 148, 0.05);
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 16px 25px 16px 16px;
  position: static;
  height: 72px;
  left: 0px;
  top: 0px;
}

.onboarding-card-clicked:hover {
  cursor: pointer;
}

.card-border {
  min-height: 48px;
  margin-bottom: -1px;
  padding: 0 24px;
  color: rgba(0, 0, 0, 0.85);
  font-weight: 500;
  font-size: 16px;
  background: transparent;
  border-bottom: 0 solid #f0f0f0 !important;
  border-radius: 0 0 0 0 !important;
}

.crausel-container {
  background-color: red;
  height: 100vh;
  position: relative;
}
.crausal-div {
  background-color: red;
  position: absolute !important;
  top: 50%;
  left: 50%;
}
.card-content-identity {
  display: flex;
  flex-direction: row;
}

.card-container {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.btn-previous {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 9px 17px;
  position: static;
  width: 100%;
  height: 38px;
  left: 0px;
  top: 0px;
  /* white */
  background: #ffffff;
  /* gray/300 */
  border: 1px solid #d1d5db;
  box-sizing: border-box;
  border-radius: 6px;
  flex: none;
  order: 0;
  flex-grow: 1;
  margin: 0px 15px;
}
.btn-next {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 9px 17px;
  position: static;
  width: 100%;
  height: 49px;
  color: #fff !important;
  background: #0b5394 !important;
  border-radius: 6px;
  flex: none;
  order: 0;
  flex-grow: 1;
}
.card-title {
  margin-left: 10px !important;
  margin-top: auto;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
}

.next-btn-container {
  width: 100%;
  margin-top: 0px !important;
}

.btn-container-onboarding {
  width: 100%;
  display: flex;
  flex-direction: row;
  margin-bottom: 0.5rem;
}

.btn-container {
  display: flex;
  flex-direction: row;
}

.input_padding {
  font-family: "Manrope";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.01em;
  color: #111827;
}

#company_website {
  height: 38px;
  border-bottom-left-radius: 0px !important;
  border-top-left-radius: 0px !important;
  margin-bottom: 0px;
}

.ant-input-group-addon {
  height: 38px;
  border-radius: 6px;
}

.past-fundraise{
  .ant-input-group-addon {
    height: 32px !important;
  }
  .ant-input {
    border-top-right-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
  }
}

.previous-btn {
  height: 49px;
  background: #ffffff;
  border: 1px solid #d1d5db;
  box-sizing: border-box;
  border-radius: 6px;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.01em;
  color: #374151;
  margin-right: 1rem;
  flex: 1;
}
.next-btn {
  height: 49px;
  background: #0b5394 !important;
  border-radius: 6px;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.01em;
  color: #ffffff !important;
  margin-left: 1rem;
  flex: 1;
  border-color: #0b5394 !important;
}

.skip-text {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.01em;
  text-decoration-line: underline;
  color: #0b5394;
  text-align: center;
  margin-top: 1rem;
}
.skip-text:hover {
  cursor: pointer;
}

.personal-info-title {
  font-family: "Manrope";
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 24px;
  letter-spacing: 0.01em;
  color: #111827;
  margin: 4px 0px !important;
}
.profile-desc {
  font-family: "Manrope";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.01em;
  color: #6b7280;
  margin: 4px 0px !important;
  margin-bottom: 1.3rem !important;
}
.input_field_margin {
  margin-top: 15px !important;
}

.country-select {
  width: 100%;
}

.avatar-position {
  float: left;
  width: 7%;
  display: flex;
  justify-content: center;
}

.content-position {
  float: left;
  width: 65%;
}

.upload-position {
  float: right;
  width: 25%;
  justify-content: center;
  justify-items: center;
  align-items: center;
  text-align: right;
  display: flex;
}

.identity-card {
  margin-bottom: 10px;
  border: 1px solid #d1d5db;
  border-radius: 8px;
  height: 94px;
}

.image-border {
  width: 45px;
  height: 45px;
  background: #ffffff;
  border-radius: 28px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #0b5394;
  font-size: 18px;
}

.image-position {
  position: relative;
  left: 25%;
}

.date-picker {
  height: 38px !important;
  background: #ffffff;
  /* gray/300 */
  border: 1px solid #d1d5db;
  box-sizing: border-box;
  border-radius: 6px;
  padding: 9px 13px;
  margin: 3px 0px;
  font-family: "Manrope";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  /* identical to box height, or 143% */

  letter-spacing: 0.01em;

  /* gray/900 */

  color: #111827;
  width: 100%;
}
::placeholder {
  font-family: "Manrope";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  /* identical to box height, or 143% */

  letter-spacing: 0.01em;

  /* gray/900 */

  color: #111827;
}
.phone_number {
  font-family: "Manrope";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  /* identical to box height, or 143% */
  /* gray/700 */
  color: #374151;
  margin: 4px 0px;
}
.country_isd {

  .ant-select-selector {
    border-radius: 6px !important;
    border-top-right-radius: 0px !important;
    border-bottom-right-radius: 0px !important;

    border-right: 0px !important;
  }

  .ant-select-selection-item {
    font-size: 14px !important;
    font-family: Manrope;
    font-weight: 400;
    color: #111827;
  }

  .ant-form-item-feedback-icon-error {
    display: none !important;
  }
}
.country-label {
  padding-top: 0.5rem;
}
.city-label {
  margin-top: 1rem !important;
}
.country-dropdown {
  margin: 3px 0px;
  width: 100%;
  padding-top: 9px;
  padding-bottom: 9px;
  border: 1px solid #d1d5db;
  border-radius: 6px;
}

#date_of_birth {
  width: 100%;
  padding-top: 9px;
  padding-bottom: 9px;
}
.btn-next:focus {
  border-color: #fff !important;
}
.btn-next:hover {
  border-color: #fff !important;
}
.previous-btn:hover {
  border-color: #d1d5db !important;
  color: #374151;
}
.previous-btn:focus {
  border-color: #d1d5db !important;
  color: #374151;
}
.next-btn:hover {
  border-color: #0b5394 !important;
}
.next-btn:focus {
  border-color: #0b5394 !important;
}
.phone_input_container {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  border: 1px solid #d1d5db;
  box-sizing: border-box;
  border-radius: 6px;
  margin: 3px 0px;
  padding: 0px;
  &.error {
    border-color: red;
  }
}
.required-star {
  color: red;
}
.required-star-ext {
  margin-left: 5px;
}
.country-code-ext {
  display: flex;
  align-items: center;
  padding: 0 0 0 0.5rem !important;
  margin-top: 0px !important;
}
.country_code {
  margin-top: 0.5rem;
  padding-left: 0.5rem;
  font-family: Manrope;
  font-size: 14px;
  font-weight: 400;
  color: #111827;
  .ant-select.ant-select-in-form-item {
    width: 100% !important;
  }
}
.phone_input_container .ant-select-selection-item {
  font-family: "Manrope";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  /* identical to box height, or 143% */
  /* Text: Secondary */
  color: #6b7280;
}
.phone_wrapper {
  display: flex !important;
  align-items: center;
}
.phone_input {
  background-color: transparent;
  height: 40px !important;
  border-radius: 6px !important;
  font-family: Manrope;
  font-size: 14px;
  font-weight: 400;
  color: #111827;
  border-radius: 8px;

  border-bottom-left-radius: 0px !important;
  border-top-left-radius: 0px !important;

  .ant-input {
    margin-bottom: 0 !important;
  }
}
.input-label {
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.01em;
  color: #374151;
  margin: 6px 0px;
}
.email-input-container {
  display: flex;
  flex-direction: column;
  margin-left: 0px;
  margin-top: 1rem;
  font-family: "Manrope";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.01em;
  color: #111827;
}
.mt-2 {
  margin-top: 5rem;
}
.padding_top {
  margin-top: 3rem !important;
  padding-top: 30px;
  background-color: red;
}
.input-container-state {
  margin-left: 1rem;
  margin-right: 1rem;
}

.contact-info {
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 24px;
  letter-spacing: 0.01em;
  margin-top: -10px;
  color: #111827;
}

.address-info-title {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.01em;
  color: #6b7280;
  margin-top: -15px;
}

.img-tag {
  height: 20px;
  width: 20px;
  margin-right: 10px;
}

.details-container {
  margin-top: 30px;
}

.react-tel-input .form-control {
  width: 100% !important;
}
.spin-align {
  text-align: center;
}
.divider {
  margin: 17px 0 32px 0;
}
.ant-select-selector {
  border-radius: 6px !important;
}
.app_header img {
  height: 65px;
  padding-top: 1rem;
  padding-left: 0.5rem;
}
@media (max-width: 992px) {
  .input-label {
    font-size: 12px;
  }
  .input-container-state {
    margin-left: 0rem !important;
    margin-right: 0rem !important;
  }
}
.mr-2 {
  margin-right: 2rem;
}
.mr-5 {
  margin-right: 5px;
}
.mt-minus-10 {
  margin-top: -2rem;
}

.content-center {
  display: flex;
  justify-content: center;
  height: 100vh;
  align-items: center;
}

.skip-text-profile:hover {
  cursor: pointer;
}

.back-button {
  text-align: center;
  font-family: Manrope;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.01em;
  text-decoration-line: underline;
  color: #0b5394;
  flex: none;
  order: 1;
  flex-grow: 0;
  margin: 20px 0px;
}

.back-button:hover {
  cursor: pointer;
}

.profile-container, .questionare-wrapper {
  .ant-form-item-required::after {
    display: block !important;
    margin-right: 4px;
    color: #ff4d4f;
    font-size: 14px;
    font-family: SimSun, sans-serif;
    line-height: 1;
    content: "*";
  }
}

@media all and (max-width: 2560px) {
  // .identity-container {
  //   margin-top: 25% !important;
  // }
  // .bankaccount-container {
  //   margin-top: 50% !important;
  // }
  // .image-upload-container {
  //   margin-top: 40% !important;
  // }
  // .investor-container {
  //   margin-top: 50% !important;
  // }
  // .profile-container {
  //   margin-top: 20% !important;
  // }
  // .questionare-container {
  //   margin-top: 10% !important;
  // }
  .btn-container-onboarding-profile {
    width: 70%;
    display: flex;
    flex-direction: row;
    margin-bottom: 0.5rem;
  }
}

@media all and (max-width: 1280px) {
  // .bankaccount-container {
  //   margin-top: 50% !important;
  // }
  // .identity-container {
  //   margin-top: 15% !important;
  // }
  // .image-upload-container {
  //   margin-top: 0% !important;
  // }
  // .investor-container {
  //   margin-top: 60% !important;
  // }
  // .profile-container {
  //   margin-top: 20% !important;
  // }
  // .questionare-container {
  //   margin-top: 0% !important;
  // }
}

@media all and (max-width: 1920px) {
  // .identity-container {
  //   margin-top: 15% !important;
  // }
  // .bankaccount-container {
  //   margin-top: 15% !important;
  // }
  // .investor-container {
  //   margin-top: 25% !important;
  // }
  // .profile-container {
  //   margin-top: 5% !important;
  // }
  // .questionare-container {
  //   margin-top: 5% !important;
  // }
  // .image-upload-container {
  //   margin-top: 10% !important;
  // }

  .btn-container-onboarding-profile {
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    flex-direction: row;
    margin-bottom: 0.5rem;
  }
  .skip-text-profile {
    text-align: center;
    //margin-left: 18rem !important;
    font-family: Manrope;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    /* identical to box height, or 143% */
    letter-spacing: 0.01em;
    text-decoration-line: underline;
    /* Primary */
    color: #0b5394;
  }

  .back-button {
    text-align: center;
    font-family: Manrope;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.01em;
    text-decoration-line: underline;
    color: #0b5394;
    flex: none;
    order: 1;
    flex-grow: 0;
    margin: 20px 0px;
  }

  .back-button:hover {
    cursor: pointer;
  }
}

@media all and (max-width: 1366px) {
  .identity-container {
    margin-top: 0% !important;
  }
  .bankaccount-container {
    margin-top: 0% !important;
  }
  .image-upload-container {
    margin-top: 5% !important;
  }
  .investor-container {
    margin-top: 0% !important;
  }
  .profile-container {
    margin-top: 0% !important;
  }
  .questionare-container {
    margin-top: 0% !important;
  }
  .skip-text-profile {
    // margin-left: 11.5rem !important;
    font-family: Manrope;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    /* identical to box height, or 143% */
    letter-spacing: 0.01em;
    text-decoration-line: underline;
    /* Primary */
    color: #0b5394;
  }
  .back-button {
    text-align: center;
    font-family: Manrope;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.01em;
    text-decoration-line: underline;
    color: #0b5394;
    flex: none;
    order: 1;
    flex-grow: 0;
    margin: 20px 0px;
  }
  .back-button:hover {
    cursor: pointer;
  }
}

.progress-bar .ant-progress-inner {
  height: 2px !important;
  width: 100% !important;
  margin-top: 1rem;
}
.progress-bar .ant-progress-success-bg,
.ant-progress-bg {
  background: #0b5394;
}

@media (max-width: 767px) {
  .ant-row {
    margin-left: 10px;
    margin-right: 10px;
  }
}
@media (max-width: 767px) {
  .ant-row {
    display: block;
  }
  .input-container-state {
    margin-left: 0px;
    margin-right: 0px;
  }
}
@media (min-width: 767px) {
  .mr-lg-1 {
    margin-right: 1rem;
  }
}

@media (max-width: 767px) {
  .profile-container {
    margin-left: 0px !important;
    margin-right: 0px !important;
  }
  .mr-0-profile {
    margin-left: 0px !important;
    margin-right: 0px !important;
  }
}

.mr-0-imageupload {
  margin-left: 0px !important;
  margin-right: 0px !important;
}

.mr-0-bankaccount {
  margin-left: 0px !important;
  margin-right: 0px !important;
}

.identity-card .ant-upload-list-text-container {
  position: absolute;
  left: 5%;
}

.file-button {
  background: #0b5394;
  color: #fff;
  border: 1px solid #0b5394;
  border-radius: 6px;
  padding: 9px 17px !important;
  display: flex;
  align-items: center;
}
.file-button:hover {
  background: #0b5394;
  color: #fff;
  border-color: #0b5394 !important;
}
.file-button:focus {
  background: #0b5394;
  color: #fff;
  border-color: #0b5394 !important;
}
.ant-upload-list-item {
  padding-bottom: 0.7rem;
}
.ant-input {
  border-radius: 6px !important;
}

@primary-color: #0B5394;