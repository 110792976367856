.add-currency{
    display: flex;
    gap: 10px;
}

.verify-social-login-wrapper {
    height: 100vh;

    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    .spining-verify {
        margin: 15px 0;
    }

    .logo-image {
        padding: 0;
    }

    .indicator {
        font-size: 30px;
        color: #58a1bf;
    }

    .verify-txt {
        text-align: center;
        font-size: 18px;
        font-weight: bold;
    }
}