.marketplace {
  white-space: break-spaces !important;

  .extra {
    display: flex;
    color: #ef4444;
    align-items: center;

    &__icon {
      margin-right: 5px;
    }

    &__text {
      color: #ef4444;
      margin: 0;
      font-style: normal;
      font-weight: 600;
      font-size: 12px;
      line-height: 16px;
    }
  }

  .marketplace-body {
    &__title {
      font-style: normal;
      font-weight: 700;
      font-size: 18px;
      line-height: 24px;
    }

    &__description {
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      color: #6b7280;
    }
  }

  .ant-table-tbody {
    white-space: break-spaces !important;
  }

  .ant-card-actions {
    background-color: #f9fafb;
  }
}

.service-marketplace-wrapper {
  justify-content: center;
  align-items: center;
  width: 100%;

  .ant-empty {
    margin-top: 50px;
  }
}

.card-grid {
  width: 100%;

  &__item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    overflow: hidden;
    text-overflow: ellipsis;
    row-gap: 10px;

    .card-progress {
      width: 30%;
    }

    .ant-typography {
      display: -webkit-box;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
      text-overflow: ellipsis;
      overflow: hidden;
    }
    .email-facilitator {
      display: flex;
      font-size: 14px;
      width: 100%;
      .ant-typography {
        -webkit-line-clamp: 1;
        overflow: hidden;
        width: 100%;
        text-overflow: ellipsis;
      }
    }
  }
}

.card-grid {
  &.message {
    display: flex;
    justify-content: center;
  }
}

.service_marketplace {
  border: 2px solid #e3e3e3;
  margin-bottom: 20px;

  .img-card-grid__item {
    justify-content: flex-start;

    h5 {
      margin-left: 10px;
      white-space: pre-wrap;
      flex: 1;
    }
  }

  .card-grid__item {
    p {
      margin-bottom: 0px;
      margin-right: 5px;
    }

    h5 {
      margin-bottom: 0px;
      margin-right: 10px;
    }
  }
}

@media screen and (max-width: 1000px) {
  .card-grid {
    &__item {
      .ant-typography {
        width: 180px;
      }
    }
  }
}

@media screen and (max-width: 765px) {
  .card-grid {
    &__item {
      .ant-typography {
        width: 280px;
      }
    }
  }
}

@media screen and (max-width: 765px) {
  .card-grid {
    &__item {
      .ant-typography {
        width: 200px;
      }
    }
  }
}

.service-tag {
  max-width: 200px;
  white-space: pre-wrap;
  margin-bottom: 5px;
}

.popover-service {
  color: #096dd9;
  cursor: pointer;
}

.dots {
  display: flex;
  flex-direction: column;
  row-gap: 3px;
}

.dot {
  background: #096dd9;
  height: 5px;
  width: 5px;
  border-radius: 50%;
}

.buttons {
  display: flex;
  flex-direction: column;
  row-gap: 10px;
}

.icons {
  display: flex;
  column-gap: 10px;
}

.ant-form-item-label {
  font-weight: 600;
}

.ant-input {
  font-weight: 100;
  opacity: 0.8;
}

.content {
  width: 200px;
}

.service_marketplace {
  height: 100%;

  &:hover {
    box-shadow: 8px 28px 50px rgba(39, 44, 49, 0.07),
      1px 6px 12px rgba(39, 44, 49, 0.04);
    transition: all 0.4s ease;
    transform: translate3D(0, -1px, 0) scale(1.03);
    .cover-avatar-group {
      transform: translate3D(0, -1px, 0) scale(1.03);
    }
  }
}

.service_marketplace .ant-card-grid.card-grid {
  box-shadow: none;
}

.service_marketplace .img-card-grid__item .ant-image-img {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  margin: 0;
  object-fit: contain;
}

.service_marketplace .img-card-grid__item .ant-image {
  padding-top: 48%;
  width: 100%;
}

.service_marketplace .ant-card-grid.card-grid:first-child {
  padding: 0;
}

.layout-dashboard .ant-card.service_marketplace {
  border-radius: 5px;
  border: 1px solid #e3e3e3;
}

.ant-card-contain-grid.service_marketplace:not(.ant-card-loading)
  .ant-card-body {
  margin: 0;
  height: 100%;
}

.service_marketplace .ant-card-grid.card-grid:last-child {
  border-top: 1px solid #e3e3e3;
  background: #f9fafb;
  padding: 24px 24px 40px;
  border-radius: 0 0 8px 8px;
}

.service_marketplace span.ant-avatar.ant-avatar-round.shape-avatar {
  position: absolute;
  right: 20px;
  background: #e7eef5;
  color: #0b5394;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

.service_marketplace .ant-card-grid.card-grid:nth-child(2) {
  padding-bottom: 0;
}

.service_marketplace .ant-card-grid.card-grid:nth-child(3) {
  padding-top: 10px;
}

.service_marketplace .card-grid__item {
  flex-wrap: wrap;
  // justify-content: flex-start;
}

.service_marketplace span.anticon.anticon-mail {
  margin-right: 10px;
}

.service_marketplace span.anticon.anticon-mail svg {
  width: 20px;
  height: 20px;
}

.service-marketplace-description {
  .ant-typography {
    color: #6b7280;
    font-size: 12px;
  }
}

.view-service-wrapper {
  width: 100%;
  height: 20px;
}

@media screen and (max-width: 765px) {
  .header-control {
    padding-bottom: 8px;
  }
}
