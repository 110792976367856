@import url("https://fonts.googleapis.com/css2?family=Inter:wght@500&family=Manrope:wght@500;700&display=swap");

body {
  margin: 0;
  font-family: "Manrope", sans-serif;
}

code {
  font-family: "Manrope", sans-serif;
}

.support-chat {
  background-color: #fff;
  width: 60px;
  height: 60px;
  position: fixed;
  bottom: 30px;
  right: 30px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  -webkit-box-shadow: 0px 0px 18px 0px rgba(0, 0, 0, 0.3);
  -moz-box-shadow: 0px 0px 18px 0px rgba(0, 0, 0, 0.3);
  box-shadow: 0px 0px 18px 0px rgba(0, 0, 0, 0.3);
  z-index: 10000000;
}
