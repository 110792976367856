.center-vertical {
  display: flex;
  align-items: center;
}
.status-txt {
  font-family: "Manrope";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.01em;
}
.dropdown-row {
  min-width: 170px;
  background-color: #ffffff;

  li {
    height: 40px;
    line-height: 40px;
  }
  .ant-col {
    margin-bottom: 20px;
  }
  img {
    margin-right: 10px;
  }
  .activate {
    color: #34d399;
  }
  .deactivate {
    color: #ef4444;
  }
  .disabled {
    color: #ef4444;
  }
}
.no-users-table-img {
  margin-top: 80px;
  margin-bottom: 50px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  .title-txt {
    font-family: "Manrope";
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 28px;
    text-align: center;
    letter-spacing: 0.01em;
    color: #101828;
  }
  .description-txt {
    font-family: "Manrope";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    text-align: center;
    letter-spacing: 0.01em;
    color: #667085;
    width: 331px;
  }
}

.change-role-modal {
  .ant-modal-body {
    margin-bottom: 0px !important;
  }
  .ant-modal-header {
    background-color: #f9fafb;
    border-radius: inherit;
  }
}
.role-modal-container {
  .ant-badge-dot {
    position: absolute;
    right: 6px;
    top: 31px;
    height: 10px;
    width: 10px;
  }
  .avatar-info {
    .ant-typography {
      margin-bottom: 0px;
      line-height: 1.5rem;
    }
  }

  .role-title {
    font-family: "Manrope";
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.01em;
    color: #374151;
    margin: 8px 0px 5px 2px !important;
  }
  .ant-tabs-content-holder {
    display: none;
  }
}
.link-detail {
  font-family: "Manrope";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.01em;
  color: #87898e;
}

.modal-title-container {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f9fafb;
  .modal-title {
    width: 132px;
    height: 24px;
    font-family: "Manrope";
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 24px;
    letter-spacing: 0.01em;
    color: #111827;
  }
}

.confirm-content {
  font-family: "Manrope";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  letter-spacing: 0.01em;
  color: #6b7280;
  margin-bottom: 6px;
}
.confirm-title {
  font-family: "Manrope";
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 28px;
  text-align: center;
  letter-spacing: 0.01em;
  color: #101828;
  margin-bottom: 10px;
}

.confirm-icon {
  margin-bottom: 20px;
}

.pointer {
  cursor: pointer !important;
}

.admin-users {
  .role-filter {
    width: 100%;

    .ant-select-selector {
      height: 38px !important;
      border-radius: 8px !important;
    }

    .ant-select-selection-placeholder,
    .ant-select-selection-item {
      line-height: 38px !important;
    }
  }

  .search_btn {
    border-radius: 8px;
  }

  .ant-tabs-content {
    padding-top: 0;
  }

  .ant-tabs-top > .ant-tabs-nav {
    margin-bottom: 30px;
  }

  .ant-table-thead > tr > th {
    background: none;
  }

  .ant-avatar {
    background: #e7eef5;
    .ant-avatar-string {
      color: #0b5394;
      font-size: 16px;
      line-height: 24px;
      font-weight: 600;
    }
  }
}

.count-badge-style .ant-badge-count {
  font-family: "Manrope";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  letter-spacing: 0.01em;
  color: #1f2937;
  width: 44px;
  height: 20px;
  background: #f3f4f6 !important;
  border-radius: 10px;
  padding: 0px 10px;
  margin-left: 8px;
}

.tab-header {
  font-family: "Manrope";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.01em;
  color: rgba(0, 0, 0, 0.85);
}

.ant-tabs-tab-active {
  .tab-header {
    color: #0b5394;
  }

  .ant-badge-count {
    background: #0b5394 !important;
    color: #ffffff;
  }
}

.ant-tabs-ink-bar {
  background: #0b5394;
}

.ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
  background: #fff;
  align-items: center;
  height: 38px;
}

.ant-select-item-option {
  background: #fff;
  align-items: center;
  height: 38px;
}
.ant-select-item-option:hover {
  background: #f9fafb;
}

.ant-select-dropdown {
  margin-top: 5px;
  border-radius: 8px;
}

.ant-modal-footer {
  padding: 15px;
}

.header-modal {
  margin-left: 0px !important;
  margin-right: 0px !important;
}

.projects-label {
  label {
    width: 100%;
  }
}