.dealtype_modal {
  max-width: 900px !important;
  width: 100% !important;
  .ant-modal-body {
    width: 100%;
    display: flex;
    padding: 40px 24px 0px !important;
    justify-content: center;
    align-items: center;
    .create-deal-form {
      margin: 0;
      width: 100%;
    }
    .head {
      .main-box {
        box-shadow: none;
        height: 100%;
        max-height: 370px;
        scroll-behavior: smooth;
        overflow-y: scroll;
      }
      .main-box::-webkit-scrollbar {
        display: block;
        width: 6px;
        background-color: #f5f5f5;
      }
      .main-box::-webkit-scrollbar-thumb {
        border-radius: 20px;
        background-color: #0b5394;
      }
    }
  }
}

@media screen and (max-width: 950px) {
  .dealtype_modal {
    max-width: 700px !important;
  }
}

@media screen and (max-width: 650px) {
  .dealtype_modal {
    max-width: 600px !important;

    .ant-modal-body {
      .head {
        .title {
          padding-left: 10px;
        }
        .heading {
          padding-left: 10px;
        }
      }
    }
  }
}

@media screen and (max-width: 500px) {
  .dealtype_modal {
    max-width: 460px !important;
    width: 100% !important;
    .ant-modal-body {
      width: 100% !important;
    }
  }
}
