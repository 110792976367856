.card-wrapper {
  &.left {
    display: flex;
    justify-content: flex-end;
    padding-right: 20px;
  }

  &.right {
    display: flex;
    justify-content: flex-start;
    padding-left: 20px;
  }

  .step-card {
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 250px;

    &__title {
      font-size: 16px;
      font-weight: 600;
    }

    &__description {
      text-align: center;
      font-size: 11px;
      color: #6b7280;
    }
  }
}
