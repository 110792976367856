.center-vertical {
  display: flex;
  align-items: center;
}
.center-vertical-1 {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.w-auto {
  width: auto !important;
}
.approve-txt {
  color:  rgb(52, 211, 153);
}
.reject-txt {
  color: rgb(239, 68, 68);
}

.compliance-container:hover{
  color : #0B5394 !important;
}

.compliance-container-ext {
  display: flex;
  justify-content: center;
  align-items: center;
}