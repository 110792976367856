.ant-modal-body {
  padding: 40px 0 0 0;
}

.card-cover-img {
  position: relative;
  cursor: pointer;

  .cover-avatar-group {
    position: absolute;
    right: 20px;
    bottom: -30px;
    z-index: 2;
    display: flex;
    align-items: center;

    &__avatar {
      box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
      cursor: pointer;
    }

    &__logo {
      position: absolute;
      left: 20px;
      top: 20px;
      box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    }
  }

  .ant-image {
    height: 100%;

    .cover-img {
      display: block;
      width: fit-content !important;
      height: 100%;
      object-fit: contain;
    }
  }
}

.cover-image {
  height: 150px;
}

.grant-info {
  display: flex;
  flex-direction: column;
  column-gap: 10px;
  padding: 40px 0 20px 0;
  row-gap: 15px;
}

.grant-head {
  display: flex;

  .heading {
    font-size: 14px;
    font-weight: 600;
    width: 100px;
  }
  .grant-name {
    cursor: pointer;
    flex: 1;
  }
  .grant-website {
    flex: 1;
    color: rgb(11, 83, 148);
    font-weight: 600;
  }
}
