.marketplace {
  white-space: break-spaces !important;
  cursor: pointer;

  .card-cover-img {
    position: relative;

    .cover-avatar-group {
      position: absolute;
      right: 20px;
      bottom: -30px;
      z-index: 2;
      display: flex;
      align-items: center;

      &__avatar {
        box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
      }

      &__logo {
        position: absolute;
        left: 20px;
        top: 20px;
        box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
      }

      &__status {
        position: absolute;
        right: 10px;
        box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
        top: 20px;
        color: #256ba1;
        border-radius: 20px;
        padding: 8px 15px;
        font-weight: 600;
      }
    }

    .cover-img {
      display: block;
      width: 100%;
      height: 100%;
    }
  }

  .extra {
    display: flex;
    color: #ef4444;
    align-items: center;

    &__icon {
      margin-right: 5px;
    }

    &__text {
      color: #ef4444;
      margin: 0;
      font-style: normal;
      font-weight: 600;
      font-size: 12px;
      line-height: 16px;
    }
  }

  .marketplace-body {
    width: 100%;
    min-height: 140px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    &__title {
      font-style: normal;
      font-weight: 700;
      font-size: 18px;
      line-height: 24px;
    }

    &__description {
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      color: #6b7280;
    }
  }

  .ant-table-tbody {
    white-space: break-spaces !important;
  }

  .ant-card-actions {
    background-color: #f9fafb;
  }
}

.no-data-mamp {
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
}
