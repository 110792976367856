.marketplace {
  white-space: break-spaces !important;
  cursor: pointer;
  .card-cover-img {
    position: relative;
    .cover-avatar-group {
      position: absolute;
      right: 20px;
      bottom: -30px;
      z-index: 2;
      display: flex;
      align-items: center;

      &__avatar {
        box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
      }

      &__logo {
        position: absolute;
        left: 20px;
        top: 20px;
        box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
      }
    }
    .cover-img {
      display: block;
      width: 100%;
      height: 100%;
    }
  }

  .extra {
    display: flex;
    color: #ef4444;
    align-items: center;

    &__icon {
      margin-right: 5px;
    }

    &__text {
      color: #ef4444;
      margin: 0;
      font-style: normal;
      font-weight: 600;
      font-size: 12px;
      line-height: 16px;
    }
  }
  .ant-card-body{
    padding:0px 10px;
  }

  .marketplace-body {
    width: 100%;
    min-height: 80px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    &__title {
      font-style: normal;
      font-weight: 700;
      font-size: 18px;
      line-height: 24px;
    }
    &__tag{
      padding:2px;
      margin-top: 20px;
      font-style: normal;
      font-weight: 700;
      font-size: 14px;
      line-height: 20px;
      align-self: center;
    }

    &__description {
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      color: #6b7280;
      margin-top:5px;
    }
    
  }

  .ant-table-tbody {
    white-space: break-spaces !important;
  }

  .ant-card-actions {
    background-color: #f9fafb;
  }
}

.no-data-mamp {
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
}

.longevity__category{
  display: flex;
  flex-wrap: wrap;
  gap:1rem;
  cursor: pointer;
  max-width: 1200px;
  p{
    display: flex;
  }
  img{
    border:1px solid grey;
    border-radius: 5px;
  }
}

.longevity__card{
  @media (max-width:768px){
    display: inline-grid;
  }
}

.overflow-hidden {
  overflow: hidden !important;
}

.pagination-component {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 80px 0px;
}


.link__device{
  margin:7px 0;
}

.social__title{
  font-size: 15px;
  line-height: 21px;
  font-weight: 600;
  margin: 25px 0 10px 0;
 
}

.social__button{
  display: flex;
  gap: 10%;
  @media (max-width:575px){
    display: grid;
  }
  button{
    display: flex;
    justify-content:center;
    align-items:center;
  text-align: left;
  border-radius: 5px;
  text-decoration: none;
  font-size: 8px;
  height: 40px;
  width: auto;
  svg{
    width: 25px;
    height: auto;
  }
  span{
    font-weight: 700;
  }
  .big-txt{
       font-size: 13px;
       font-weight: 900;
  }
  }
}
