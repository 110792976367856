.main-box {
  height: 500px;
  width: 100%;
  box-shadow: rgba(17, 17, 26, 0.1) 0px 4px 16px,
    rgba(17, 17, 26, 0.1) 0px 8px 24px, rgba(17, 17, 26, 0.1) 0px 16px 56px;
  display: grid;
  overflow-y: auto;
  overflow-x: hidden;
  grid-template-columns: repeat(auto-fill, minmax(240px, 1fr));
  grid-auto-rows: min-content;
  -ms-overflow-style: none;
  scrollbar-width: none;
  padding: 20px;
  gap: 15px;
  margin-bottom: 30px;

  .box {
    // margin: 1.65%;
    padding: 10px;
    height: 80px;
    // width: 90%;
    border: 1px solid #d6d6d6;
    box-shadow: 0 2px 3px 0px rgba(0, 0, 0, 0.25);
    border-radius: 3px;
    transition: 0.2s all;
    color: rgba(0, 0, 0, 0.85);
    cursor: pointer;
    .detail-card {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      height: 100%;
      .invest-tech-card {
        font-size: 16px;
      }
    }
    .detail {
      display: flex;
      justify-content: space-between;
      align-items: center;
      height: 50%;
      .name {
        font-size: 12px;
        font-weight: 400;
      }
    }
    img {
      height: 40px;
      width: 40px;
      border-radius: 50%;
    }
  }
}

.main-box::-webkit-scrollbar {
  display: block;
  width: 6px;
  background-color: #f5f5f5;
}
.main-box::-webkit-scrollbar-thumb {
  border-radius: 20px;
  background-color: #0b5394;
}

.head {
  display: flex;
  flex-direction: column;
  .deal-type {
    display: flex;
    width: 850px;
    flex-wrap: wrap;
    flex: 0 0 33.333333%;
  }
  .header-deal-type {
    width: 250px;
    display: flex;
    justify-content: space-between;
  }
  input {
    height: 15px;
    width: 30px;
    position: relative;
    top: 2px;
    cursor: pointer;
  }
  .label1 {
    flex: 1 1 230px;
    margin-right: 20px;
  }

  .title {
    font-size: 18px;
    padding-bottom: 10px;
  }

  .heading {
    font-size: 18px;
    padding-top: 12px;
    padding-right: 30px;
    margin-bottom: 10px;
  }
}

.deal-type-selection {
  .ant-row {
    width: 100%;
  }
}
.box-background {
  background: #0b5394;
  color: #fff !important;
}
.email {
  padding-left: 15px;
  text-overflow: ellipsis;
  position: relative;
  white-space: nowrap;
  overflow: hidden;
}
@media screen and (max-width: 1300px) {
  .main-box {
    .box .detail .name {
      font-size: 12px;
    }
  }
  .head {
    .deal-type {
      flex: 50%;
      width: 700px;
    }
    .label1 {
      flex: 1 1 290px;
    }
  }
}

@media screen and (max-width: 1100px) {
  .main-box {
    .box .detail .name {
      font-size: 10px;
    }
  }
  .head {
    .deal-type {
      width: 600px;
    }
    .label1 {
      flex: 1 1 250px;
    }
  }
}

@media screen and (max-width: 1000px) {
  .main-box {
    .box .detail .name {
      font-size: 12px;
    }
  }
  .head {
    .deal-type {
      width: 500px;
    }
    .label1 {
      flex: 1 1 200px;
    }
  }
}

@media screen and (max-width: 900px) {
  .head {
    .deal-type {
      width: 400px;
    }
    .label1 {
      flex: 1 325px;
    }
  }
}

@media screen and (max-width: 800px) {
  .main-box {
    .box .detail .name {
      font-size: 10px;
    }
  }
  .head {
    .deal-type {
      width: 600px;
    }
    .label1 {
      flex: 1 1 250px;
    }
  }
}

@media screen and (max-width: 600px) {
  .main-box {
    .box .detail .name {
      font-size: 12px;
    }
  }
  .head {
    .deal-type {
      width: 450px;
    }
    .label1 {
      flex: 1 400px;
    }
  }
}
