.longevity-detail-modal {
  .ant-modal-content {
    padding-right: 2px;
  }
  ::-webkit-scrollbar {
    width: 3px;
  }
  ::-webkit-scrollbar-track {
    border-radius: 8px;
  }
  ::-webkit-scrollbar-thumb {
    background: #0b5394;
  }
  ::-webkit-scrollbar-track-piece:end {
    margin-bottom: 7px;
  }
  ::-webkit-scrollbar-track-piece:start {
    margin-top: 7px;
  }

  .ant-modal-body {
    padding: 0 0 40px 0 !important;
    max-height: 83vh;
    overflow-y: scroll;
    border-radius: 8px;
  }
}

.card-cover-img {
  position: relative;
  cursor: pointer;

  .cover-avatar-group {
    position: absolute;
    right: 20px;
    bottom: -30px;
    z-index: 2;
    display: flex;
    align-items: center;

    &__avatar {
      box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
      cursor: pointer;
    }

    &__logo {
      position: absolute;
      left: 20px;
      top: 20px;
      box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    }
  }

  .cover-img {
    display: block;
    width: 100%;
    height: 100%;
  }
}

.cover-image {
  height: 150px;
}

.longevity {
  &__info {
    display: flex;
    flex-direction: column;
    column-gap: 10px;
    padding: 20px 20px 0 20px;
    row-gap: 15px;
  }

  &__title {
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 24px;
  }

  &__subtitle {
    font-style: normal;
    font-weight: 600;
    font-size: 15px;
    line-height: 22px;
  }

  &__desc {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #6b7280;
    // margin-top:5px;
  }
  &__promo {
    .anticon-copy {
      float: right;
      margin-top: 3px;
      &:hover,
      &:focus {
        color: #256ba1;
        border-color: #256ba1;
        background: #fff;
      }
    }
    span {
      color: rgba(0, 0, 0, 0.85);
    }
  }
}
