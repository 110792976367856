.menu {
  .ant-menu {
    .ant-menu-item-selected {
      box-shadow: none;
      border-radius: 0px;
      background-color: #dae1ee !important;
      border: 0px;
      font-weight: 600;
    }
  }
}

.selected-item {
  box-shadow: none;
  border-radius: 2px;
  border: 2px solid gray;
}

.next-steps-button {
  margin-right: 14px;
  span {
    vertical-align: baseline !important;
  }
}

.search_menu {
  padding: 0px 16px 10px;
  font-weight: 500;
  font-size: 16px;
  font-family: "Manrope";
  font-style: normal;
  display: flex;
  align-items: center;
  white-space: nowrap;

  .search_input {
    background-color: #f1f2f4 !important;
    border-radius: 4px;
    min-height: 35px;
    input {
      background-color: #f1f2f4 !important;
    }
  }
}

.modal_search_input {
  max-width: 400px;
}

.sidepanel-header {
  margin-bottom: 8px;
  .ant-collapse-header {
    background: #0b5394 !important;
    color: white !important;
    padding: 8px 16px !important;
    font-weight: 500;
    font-size: 16px;
    font-family: "Manrope";
    font-style: normal;
  }
  .ant-collapse-content > .ant-collapse-content-box {
    padding-top: 8px !important;
    padding-bottom: 0px !important;
    .side-menu-item {
      height: 32px !important;
    }
  }
}


.shopping-cart-items{
  max-height: 0;
  overflow-y: hidden;
  transition: max-height 0.1s ease-out;
  display:flex;
  flex-direction: column;
  gap: 1rem;
  padding:0.2rem 0.1rem;
  
}
.nav-cart:has(.basket-hover:hover, .shopping-cart-items:hover){
  .shopping-cart-items{
    max-height: 1000px;
    transition: max-height 0.1s ease-in;
    border: 1px solid rgba(128, 128, 128, 0.471);
  }

}



.right-drop-down { 
  position: absolute;
  left: 100%;
  width: 300px;
  border: 2px solid red;
}