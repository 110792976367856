.deal__card {
  .financial_statics_title {
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
  }

  .ant-statistic-content {
    font-weight: 700;
    font-size: 30px;
    line-height: 38px;
  }
}
.analytics__table {
  .ant-table-thead {
    tr th {
      font-size: 14px;
      font-weight: 900;
    }
  }
  .ant-pagination {
    display: flex;
    justify-content: center;
  }

  .document__format {
    max-width: 300px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }

  @media (max-width: 481px) {
    .ant-table-tbody {
      tr > td {
        padding: 7px;
      }
    }
  }
}
