@import "~antd/dist/antd.less";

.App-header {
  font-family: Manrope;
  background-color: #ffffff;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: rgb(0, 0, 0);
}
.app_content {
  font-family: Manrope;
  background-color: #ffffff;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: rgb(0, 0, 0);
}

.projectDataTable {
  display: flex;
  justify-content: space-around;
  align-items: center;
  border: 2px solid rgb(235, 216, 216);
  border-radius: 7px;
  padding: 7px 0px 2px 0px;
  margin-top: 10px;
}

@primary-color: #0B5394;