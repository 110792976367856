.marketplace {
  white-space: break-spaces !important;
  cursor: pointer;
  .card-cover-img {
    position: relative;
    .cover-avatar-group {
      position: absolute;
      right: 20px;
      bottom: -30px;
      z-index: 2;
      display: flex;
      align-items: center;

      &__avatar {
        box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
      }

      &__logo {
        position: absolute;
        left: 20px;
        top: 20px;
        box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
      }
    }
    .cover-img {
      display: block;
      width: 100%;
      height: 100%;
    }
  }

  .extra {
    display: flex;
    color: #ef4444;
    align-items: center;

    &__icon {
      margin-right: 5px;
    }

    &__text {
      color: #ef4444;
      margin: 0;
      font-style: normal;
      font-weight: 600;
      font-size: 12px;
      line-height: 16px;
    }
  }

  .marketplace-body {
    width: 100%;
    min-height: 140px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    &__title {
      font-style: normal;
      font-weight: 700;
      font-size: 18px;
      line-height: 24px;
    }

    &__description {
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      color: #6b7280;
    }
  }

  .ant-table-tbody {
    white-space: break-spaces !important;
  }

  .ant-card-actions {
    background-color: #f9fafb;
  }
}

.no-data-mamp {
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
}

.investor-detail-container {
  .flex-v-h-c {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.background-color-cmn {
  background-color: #f9fafb !important;
}

.justify-flex-start {
  justify-content: flex-start !important;
}

.inv-detail-container {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  column-gap: 20px;

  .item {
    padding: 20px;
    border-radius: 20px;
    margin-top: 10px;

    .header-left {
      display: flex;
      justify-content: space-between;
      align-items: center;
      height: 75px;
      flex-wrap: wrap-reverse;
    }

    .img {
      height: 75px;
      width: 100%;
    }

    .dot {
      background-color: grey;
      height: 20px;
      width: 20px;
      border-radius: 50px;
      margin-right: 10px;
    }
    .title {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      padding-bottom: 30px;
    }
    .ant-typography {
      margin-bottom: 0px !important;
    }
    .pb-20 {
      padding-bottom: 20px;
    }
  }
}

.drawer {
  .header-left {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 75px;
    flex-wrap: wrap-reverse;
  }

  .img {
    height: 75px;
    width: 100%;
  }

  .dot {
    background-color: grey;
    height: 20px;
    width: 20px;
    border-radius: 50px;
    margin-right: 10px;
  }
  .title {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding-bottom: 30px;
  }
  .ant-typography {
    margin-bottom: 0px !important;
  }
  .pb-20 {
    padding-bottom: 20px;
  }
}

.infinite-scroll-container {
  overflow: unset !important;
}

.overflow-hidden {
  overflow: hidden !important;
}

.end-loader {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: auto;
  padding-top: 30px;
}
.pagination-component {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 80px 0px;
}
.pb-10 {
  padding-bottom: 10px;
}

.mt-50 {
  margin-top: 50px;
}

.tab-of-modal {
  .header-left {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap-reverse;
  }

  .img {
    height: 140px;
    width: auto;
    padding: 10px;
  }

  .dot {
    background-color: #0b5394;
    height: 20px;
    width: 20px;
    border-radius: 50px;
    margin-right: 10px;
  }
  .title {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 30px 0px;
  }
  .ant-typography {
    margin-bottom: 0px !important;
  }
  .pb-20 {
    padding-bottom: 20px;
  }

  .flex-center {
    display: flex;
    justify-content: center;
  }
  .flex-end {
    display: flex;
    justify-content: flex-end;
  }
  .flex-center {
    display: flex;
    justify-content: center;
  }
  .ant-list-item {
    border-bottom: 0px !important;
  }
  .ant-list-footer{
    display: flex;
    justify-content: center;
  }
}

.inv-modal {
  .ant-tabs {
    height: 100%;
  }
  .ant-modal-body{
    padding: 40px 24px 0 !important;
  }

  .ant-tabs-content-holder {
    height: 100%;
    overflow: auto;
  }

  .ant-tabs-content-holder::-webkit-scrollbar {
    width: 5px;
  }

  /* Track */
  .ant-tabs-content-holder::-webkit-scrollbar-track {
    background: #e7eef5;
  }

  /* Handle */
  .ant-tabs-content-holder::-webkit-scrollbar-thumb {
    background: #0b5394;
  }

  /* Handle on hover */
  .ant-tabs-content-holder::-webkit-scrollbar-thumb:hover {
    background: #0b5494a9;
  }

  .ant-tabs-content {
    padding-top: 0px !important;
  }

  .ant-tabs-nav {
    margin: 0px !important;
  }
  .ant-list-header {
    padding: 0px !important;
  }
  .ant-form-item {
    margin-bottom: 0px !important;
  }
  .btn-container {
    padding: 50px 0px 0px 0px;
    display: flex;
    justify-content: flex-end;
  }
  .form-get-in-touch {
    padding: 15px 20%;
  }
  .ant-select-selector {
    height: 40px !important;
  }
  .matching-tag {
    cursor: pointer;
    margin: 5px;
  }
  .matching-tag:hover {
    color: blue;
  }
  .pb-5 {
    padding-bottom: 10px;
  }
  .p-10 {
    padding: 10px;
  }
  .empty-image {
    height: 330px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.comparative-analysis-container {
  .list-container-col {
    padding: 10px 5px;
  }
  .list {
    border: 1px solid #f0f0f0;
    border-radius: 8px;
    height: 370px;
    overflow-y: auto;
  }
  .list-item {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    height: 100%;
  }
  .title {
    padding: 10px 0px;
    font-weight: bold;
    font-size: 14px;
  }

  .avtar-image {
    height: 60px;
    width: 60px;
    border-radius: 0px;
  }
  .ant-list-item-meta-description {
    color: black !important;
    font-size: 14px;
    line-height: 1.5715;
  }
  .ant-list::-webkit-scrollbar {
    width: 2px;
  }

  /* Track */
  .ant-list::-webkit-scrollbar-track {
    background: #e7eef5;
  }

  /* Handle */
  .ant-list::-webkit-scrollbar-thumb {
    background: #0b5394;
  }

  /* Handle on hover */
  .ant-list::-webkit-scrollbar-thumb:hover {
    background: #0b5494a9;
  }
}

@media screen and (max-width: 800px) {
  .inv-detail-container {
    display: flex;
    flex-direction: column;
  }
}
