.marketplace {
  white-space: break-spaces !important;
  cursor: pointer;

  &:hover {
    box-shadow: 8px 28px 50px rgba(39, 44, 49, 0.07),
      1px 6px 12px rgba(39, 44, 49, 0.04);
    transition: all 0.4s ease;
    transform: translate3D(0, -1px, 0) scale(1.03);
    .cover-avatar-group {
      transform: translate3D(0, -1px, 0) scale(1.03);
    }
  }

  .ant-tag {
    border-radius: 5px;
  }

  .marketplace-action {
    display: flex;
    flex-direction: column;

    &__progress {
      margin-top: 5m run startpx;
    }

    &__label {
      margin: 0;
    }

    &__title {
      margin-top: 10px;
    }
  }

  .card-cover-img {
    position: relative;
    cursor: pointer;

    .cover-avatar-group {
      position: absolute;
      right: 20px;
      bottom: -30px;
      z-index: 2;
      display: flex;
      align-items: center;

      &__avatar {
        box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
        cursor: pointer;
      }

      &__logo {
        position: absolute;
        left: 20px;
        top: 20px;
        box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
      }
    }

    .cover-img {
      display: block;
      width: 100%;
      height: 100%;
    }
  }

  .extra {
    display: flex;
    color: #ef4444;
    align-items: center;

    &__icon {
      margin-right: 5px;
    }

    &__text {
      color: #ef4444;
      margin: 0;
      font-style: normal;
      font-weight: 600;
      font-size: 12px;
      line-height: 16px;
    }
  }

  .marketplace-body {
    &__title {
      font-style: normal;
      line-height: 24px;
      display: flex;
      justify-content: space-between;
      margin-top: 20px;
      font-style: normal;
      font-weight: 500;
      font-size: 22px;
      line-height: 24px;
      color: #0281a9;
    }

    .grant-name {
      width: 75%;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    &__description {
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      color: black !important;
      min-height: 63px;
      cursor: pointer;
    }
  }

  .ant-table-tbody {
    white-space: break-spaces !important;
  }

  .website {
    background-color: #f9fafb;
    padding: 0 20px;
    -webkit-line-clamp: 1;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    color: rgb(11, 83, 148) !important;
    cursor: pointer;
  }
}

.layout-dashboard .ant-card {
  border-radius: 15px;
  overflow: hidden;
}

@media (max-width:768px) {
  .mb-1 { 
    margin-bottom: 1rem;
  }
}