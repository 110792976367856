.user-sider {
  .ant-tabs-content {
    padding: 5px;
  }

  .message-button {
    margin-left: 5px;
  }

  &__header {
    display: flex;
    align-items: center;

    .user-photo {
      margin-right: 10px;
    }

    .top-title_title {
      font-family: "Manrope";
      font-style: normal;
      font-weight: 600;
      font-size: 24px;
      line-height: 36px;
      letter-spacing: 0.01em;
      color: #101828;
      margin: 0;
    }

    .description {
      font-family: "Manrope";
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 24px;
      letter-spacing: 0.01em;
      color: #0b5394;
    }

    margin-bottom: 10px;
  }

  .list-item {
    margin-bottom: 10px;

    &__label {
      font-family: "Manrope";
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 20px;
      letter-spacing: 0.01em;
      color: #6b7280;
    }

    &__value {
      font-family: "Manrope";
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      letter-spacing: 0.01em;
      color: #111827;

      &.website {
        color: #0b5394;
      }
    }
  }
}