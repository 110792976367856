.menu-link {
  padding: 10px 16px;
  color: #6b7280 !important;
  border-radius: 8px;
  font-weight: 500;
  font-size: 16px;
  font-family: "Manrope";
  font-style: normal;
  display: flex;
  align-items: center;
  white-space: nowrap;
  height: 48px;

  .icon {
    display: inline-flex;
    width: 32px;
    height: 32px;
    background-color: #fff;

    border-radius: 6px;
    justify-content: center;
    align-items: center;
    margin-right: 11px;
    vertical-align: middle;
  }

  .icon-nav {
    margin-right: 10px;
    width: 35px;
    height: 35px;
  }

  .unreaded {
    position: relative;

    &:after {
      content: "";
      background-color: #0b5394;
      border-radius: 50%;
      width: 7px;
      height: 7px;
      position: absolute;
      top: 6px;
      right: 7px;
    }
  }

  &.active {
    background-color: #f9fafb;
    color: #111827 !important;
    font-weight: 500;
    font-size: 16px;
    font-family: "Manrope";
    font-style: normal;
  }

  &:hover {
    color: #111827 !important;
  }
}

.header-brand {
  text-align: center;

  img {
    height: 45px;
  }
}

.next-steps-button {
  margin-right: 10px;
  padding: 4px 15px !important;
  span {
    vertical-align: baseline !important;
  }
}

.edit-menu {
  min-width: 170px;
  z-index: 10000000 !important;

  .ant-dropdown-menu-title-content {
    display: flex;
    color: #8c8c8c;

    p {
      margin-bottom: 0;
    }
  }

  &__icon {
    margin-right: 5px;
  }
}

.mobile-nav {
  .side-menu-item {
    display: block !important;
    background: none !important;
    border: none !important;
    box-shadow: none !important;
  }

  .ant-drawer-close {
    top: 25px;
    left: 290px;
  }

  .brand {
    img {
      height: 65px;
    }
  }

  .ant-drawer-close {
    position: fixed;
    left: 280;
  }

  .ant-drawer-body {
    padding: 0 !important;
    margin-bottom: 70px;
  }

  .ant-menu-item {
    margin: 0;
    overflow: visible !important;
    padding-left: 10px !important;
  }
}

.ant-menu-submenu {
  padding: 0 !important;
  height: auto;
  line-height: normal;
}

.ant-menu-title-content {
  a {
    padding: 10px 16px;
    color: #6b7280;
    border-radius: 8px;
    font-weight: 500;
    font-size: 16px;
    font-family: "Manrope";
    font-style: normal;
    display: flex;
    align-items: center;
    white-space: nowrap;
  }
}

// .ant-list-item {
//   flex-wrap: wrap;
//   padding: 14px;
//   background: #fff;
// }

.ant-list-item-meta {
  width: 100%;
  flex: 1 1 auto;
}

.ant-menu-item-selected {
  box-shadow: 0px 20px 27px #0000000d;
  border-radius: 8px;
  background: #fff;
  border: 0px;
  font-weight: 600;
}
.ant-list-split .ant-list-item {
  // flex-wrap: wrap;
  background: #fff;
  padding: 12px;
}

.ant-list-split .ant-btn-link {
  padding-right: 0;
}
li::after {
  display: none;
}

.ant-menu-submenu .ant-menu-submenu:active {
  background-color: transparent;
}
