.home_wrapper{
    animation: FlSvMV 1s ease;
    box-sizing: content-box;
    position: fixed;
    text-align: center;
    top: calc(50% - 150px);
    width: 100%;

    .home-title-wrapper {
        font-size: 48px;
        font-weight: bolder;
        margin: 0px;
    }

    .form-input-wrapper{
        display: flex;
        justify-self: center;
        align-items: center;
        flex-direction: column
    }
    .home-page-text {
        font-size: 16px;
    }
    .form-input {
        margin-top: 15px;
        font-size: 18px;
    }
    .form-button {
        background: #0b5394;
        border: none;
        border-radius: 6px;
        color: #fff;
        font-size: 22px;
        margin: 24px 0;
        padding: 5px 70px;
        cursor: pointer;
    }
}
.form-close-icon {
    cursor: pointer;
    font-size: 28px;
    height: 30px;
    position: fixed;
    right: 60px;
    top: 60px;
    width: 30px;
    z-index: 3;
}
