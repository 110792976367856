.steps-content {
  min-height: 200px;
  margin-top: 16px;
  padding-top: 80px;
  text-align: center;
  border: 1px dashed #e9e9e9;
  border-radius: 2px;

  .ant-timeline-item-head {
    padding: 0;
  }

  .step-icon {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    .blue-circle {
      color: #0b5394;
      border-color: #0b5394;
      border-radius: 50%;
      width: 10px;
      height: 10px;
      border: 2px solid #0b5394;
    }
  }
}

.jorney-card {
  text-align: left;
  cursor: pointer;
  border-color: #0b5394;
  .ant-card-head {
    border-color: #0b5394;
  }
  &.jorney-card-success {
    background-color: #53c41a3d;
  }

  &.left {
    &:hover {
      box-shadow: 5px 5px 5px #0b5394;
    }
  }

  &.right {
    &:hover {
      box-shadow: -5px 5px 5px #0b5394;
    }
  }

  .ant-card-head-title {
    font-weight: 600;
  }
}
