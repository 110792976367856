.invest {
  height: 100vh;

  .currency-input {
    .ant-input {
      border-top-right-radius: 0 !important;
      border-bottom-right-radius: 0 !important;
    }

    .ant-input-group-addon {
      height: 32px;
    }
  }

  .bank-cards {
    display: flex;
    flex-direction: column;
  }

  .ant-btn {
    border-radius: 6px !important;
    overflow: hidden;
  }

  .content-row {
    margin: 0 auto;
    width: 550px;
    margin-bottom: 10px;
  }

  .ant-card-head {
    width: 100%;
  }

  .ant-radio-wrapper {
    align-items: center;

    .radio-label {
      display: flex;
      flex-direction: column;
    }
  }

  .lable-second {
    color: #6b7280;
  }

  .ant-btn-primary {
    border-color: #0b5394;
    background: #0b5394;
    font-family: "Manrope";
    font-size: 14px;
    font-weight: 300;
    line-height: 20px;
    letter-spacing: 0.01em;
    text-align: center;
  }

  .ant-btn-link {
    color: #0b5394;
  }

  &__sider {
    height: 100vh;
    border-right: 1px solid rgba(229, 231, 235, 1);
    color: #fff;
    line-height: 120px;
    background: #f9fafb;

    @media (max-width: 1100px) {
      position: absolute;
      right: 0;
    }

    .ant-layout-sider-children {
      display: flex;
      flex-direction: column;
      justify-content: center;
      .ant-menu-inline-collapsed {
        background: transparent;
      }
      .ant-menu-item-only-child {
        padding: 0 18px;
        font-size: 16px;
        font-family: "Manrope";
        font-weight: bold;
      }
    }

    .ant-card {
      width: 400px;
      margin: 0 auto;
      .card-grid {
        width: 100%;
        padding: 14px 24px;

        &__item {
          display: flex;
          justify-content: space-between;
          align-items: center;

          p,
          h5 {
            margin: 0;
          }
        }
      }
    }
  }

  &__header {
    background-color: white;
    color: black;
    border-bottom: 1px solid rgba(229, 231, 235, 1);
    padding: 0;

    .header-layout {
      display: flex;
      justify-content: space-between;
      align-items: center;

      .left {
        display: flex;
        align-items: center;
        margin-left: 20px;

        .header-brand {
          margin-right: 13px;

          img {
            height: 65px;
          }
        }
      }

      .right {
        display: flex;
        align-items: center;
        margin-right: 20px;

        .save-drafts {
          margin-left: 13px;
        }
      }
    }
  }

  &__content {
    color: black;
    background: #fff;
    display: flex;
    flex-direction: column;
    justify-content: center;
    @media (max-width: 1200px) {
      padding: 0 20px;
    }
  }

  .ant-layout-sider-trigger {
    background-color: #0b5394;
  }

  .height-100 {
    height: -moz-available;
    height: -webkit-fill-available;
  }
}

@media (max-width: 768px) {
  .invest {
    .content-row {
      width: 300px;
    }

    &__sider {
      .ant-card {
        max-width: 350px;
      }
    }
  }
}

@media (max-width: 576px) {
  .invest {
    &__sider {
      min-width: auto !important;
      max-width: 400px !important;
      .ant-card {
        max-width: auto;
      }
    }
  }
}

.modal-form__invest-body {
  .ant-form-vertical {
    width: 100%;

    .ant-form-item {
      margin-bottom: 10px;
    }
  }

  .ant-form-item-required::after {
    display: block !important;
    margin-right: 4px;
    color: #ff4d4f;
    font-size: 14px;
    font-family: SimSun, sans-serif;
    line-height: 1;
    content: "*";
  }
}

.input-number-style {
  width: 100%;
  border-radius: 5px;
}

.invest-modal {
  .ant-modal-content {
    .ant-modal-body {
      .ant-modal-confirm-body-wrapper {
        .ant-modal-confirm-body {
          text-align: center;
          display: flex;
          justify-content: center;
          align-items: center;
          flex-direction: column;
          .ant-modal-confirm-content {
            margin-left: unset;
          }
        }
        .ant-modal-confirm-btns {
          text-align: center;
          .ant-btn {
            border-radius: 6px;
          }
        }
      }
    }
  }
}
