.upload-documents {
    width: 100% !important;

    .ant-upload {
        width: 100% !important;
    }
}

.investment-documents-list {
    .ant-tabs-content {
        padding-top: 0;
    }

}

.permission-list {
    width: 100%;

    .document-reject {
        color: red !important;
    }
}