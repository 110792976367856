.admin-deals {
  .count-badge-style .ant-badge-count {
    font-family: "Manrope";
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    text-align: center;
    letter-spacing: 0.01em;
    color: #1f2937;
    width: 44px;
    height: 20px;
    background: #f3f4f6 !important;
    border-radius: 10px;
    padding: 0px 10px;
    margin-left: 8px;
  }

  .tab-header {
    font-family: "Manrope";
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.01em;
    color: rgba(0, 0, 0, 0.85);
  }

  .ant-tabs-tab-active {
    .tab-header {
      color: #0b5394;
    }

    .ant-badge-count {
      background: #0b5394 !important;
      color: #ffffff;
    }
  }

  .ant-tabs-ink-bar {
    background: #0b5394;
  }
}
